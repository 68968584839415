/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
button[data-v-2478480a] {
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
}
button .action-icon[data-v-2478480a] {
  transition: scale 0.2s ease-in;
}
button[data-v-2478480a]:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
button:hover .action-icon[data-v-2478480a] {
  scale: 1.2;
}
button:active:not(:disabled) .action-icon[data-v-2478480a] {
  scale: 1.1;
}
.base-btn[data-v-2478480a] {
  padding: 1rem;
  background-color: #f8f9fa;
  border: none;
  border-radius: 0.5rem;
}
.primary-btn[data-v-2478480a] {
  background-color: #e93f6f;
  color: white;
}
.primary-btn[data-v-2478480a]:hover:not(:disabled), .primary-btn[data-v-2478480a]:focus:not(:disabled), .primary-btn[data-v-2478480a]:active:not(:disabled) {
  background: #dc1950;
}
.primary-btn[data-v-2478480a]:active:not(:disabled) {
  background: #ee6d91;
}
.edit-btn[data-v-2478480a] {
  text-decoration: underline;
  color: #e93f6f;
}
.edit-btn[data-v-2478480a]:hover:not(:disabled), .edit-btn[data-v-2478480a]:focus:not(:disabled) {
  background-color: transparent;
}
.edit-btn:active:not(:disabled) .action-icon[data-v-2478480a] {
  color: #e93f6f;
}
.alt-btn[data-v-2478480a] {
  background-color: transparent;
  color: #e93f6f;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  width: 100%;
}
.alt-btn[data-v-2478480a]:hover {
  background-color: #e93f6f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
}
.alt-btn[data-v-2478480a]:active {
  background-color: #e93f6f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
}
.alt-btn[data-v-2478480a]:focus {
  outline: none;
  box-shadow: 0 0 0 3px #e93f6f;
}
.alt-btn-two[data-v-2478480a] {
  background-color: transparent;
  color: #e93f6f;
  border: 1px solid #e93f6f;
  border-radius: 5px;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  margin-left: 1rem;
  font-size: 1.3rem;
  width: 12rem;
}
.alt-btn-two[data-v-2478480a]:hover {
  background-color: #e93f6f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
}
.alt-btn-short[data-v-2478480a] {
  background-color: transparent;
  color: #e93f6f;
  border: 1px solid #e93f6f;
  border-radius: 5px;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  margin-left: 1rem;
  font-size: 1.3rem;
  width: unset;
}
.alt-btn-short[data-v-2478480a]:hover {
  background-color: #e93f6f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
}