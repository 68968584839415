/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.base-modal[data-v-4930085c] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(34, 34, 34, 0.281);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.base-modal.show-modal[data-v-4930085c] {
  z-index: 5 !important;
  animation: show-options-data-v-4930085c 0.5s ease-in both;
}
.base-modal.modal-xs .selection-holder[data-v-4930085c] {
  width: 20vw;
}
.base-modal.modal-s .selection-holder[data-v-4930085c] {
  width: 30vw;
}
.base-modal.modal-sm .selection-holder[data-v-4930085c] {
  width: 40vw;
}
.base-modal.modal-m .selection-holder[data-v-4930085c] {
  width: 60vw;
}
.base-modal.modal-lg .selection-holder[data-v-4930085c] {
  width: 80vw;
}
.base-modal.modal-xl .selection-holder[data-v-4930085c] {
  width: 90vw;
}
.base-modal .selection-holder[data-v-4930085c] {
  position: relative;
  height: auto;
  background-color: white;
  box-shadow: 1px 2px 10px 2px #afafaf;
  border-radius: 6px;
  width: 70vw;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}
@keyframes show-options-data-v-4930085c {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}